<template>
  <Landing />
</template>

<script>
import Landing from '@/components/Landing.vue';

export default {
  components: { Landing },
};
</script>

<style lang="scss" scoped></style>
